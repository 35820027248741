import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin.vue'),
    redirect: '/admin/profiles',
    children: [
      {
        path: '/admin/profiles',
        name: 'AdminProfiles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminProfiles.vue'),
      },
      {
        path: '/admin/tasks',
        name: 'AdminTasks',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminTasks.vue'),
      },
      {
        path: '/admin/search/reports',
        name: 'AdminSearchReports',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminSearchReports.vue'),
      },
      {
        path: '/admin/search/unmanagedreports',
        name: 'AdminSearchUnmanagedReports',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminSearchUnmanagedReports.vue'),
      },
      {
        path: '/admin/search/vehicles',
        name: 'AdminSearchVehicles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminVehicles.vue'),
      },
      {
        path: '/admin/search/municipalities',
        name: 'AdminSearchMunicipalities',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminMunicipalities.vue'),
      },
      {
        path: '/admin/import/reports',
        name: 'AdminImportReports',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportReports.vue'),
      },
      {
        path: '/admin/import/drivers',
        name: 'AdminImportDrivers',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportDrivers.vue'),
      },
      {
        path: '/admin/import/vehicles',
        name: 'AdminImportVehicles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportVehicles.vue'),
      },
      {
        path: '/admin/import/vehicles/owned',
        name: 'AdminImportVehicles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportOwnedVehicles.vue'),
      },
      {
        path: '/admin/import/newvehicles',
        name: 'AdminImportNewVehicles',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportNewVehicles.vue'),
      },
      {
        path: '/admin/import/police',
        name: 'AdminImportPolice',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportPolice.vue'),
      },
      {
        path: '/admin/import/driversContract',
        name: 'AdminImportPolice',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportDriversContract.vue'),
      },
      {
        path: '/admin/import/create',
        name: 'AdminImportCreate',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportCreate.vue'),
      },
      {
        path: '/admin/import/locationdatascan',
        name: 'AdminLocationDataScan',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminLocationDataScan.vue'),
      },
      {
        path: '/admin/import/generatealerts',
        name: 'AdminAlertGenerator',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminAlertGenerator.vue'),
      },
      {
        path: '/admin/analytics/yearly',
        name: 'AdminAnalyticsYearly',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminAnalyticsYearly.vue'),
      },
      {
        path: '/admin/crawlReports',
        name: 'AdminCrawlReports',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminCrawlReports.vue'),
      },
      {
        path: '/addreport',
        name: 'addReport',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/addReport.vue'),
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
  },
  {
    path: '/contracts/:id',
    name: 'VehicleContract',
    component: () => import(/* webpackChunkName: "vehicleContractEntity" */ '@/views/VehicleContractEntity.vue'),
  },
  {
    path: '/driverContract/:id',
    name: 'DriverContract',
    component: () => import(/* webpackChunkName: "driverContractEntity" */ '@/views/DriverContractEntity.vue'),
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import(/* webpackChunkName: "tasks" */ '@/views/Tasks.vue'),
  },
  {
    path: '/accidents',
    name: 'Accidents',
    component: () => import(/* webpackChunkName: "accidents" */ '@/views/Accidents.vue'),
  },
  {
    path: '/accidents/:id',
    name: 'Accident',
    component: () => import(/* webpackChunkName: "accidents" */ '@/views/AccidentEntity.vue'),
  },
  {
    path: '/reports/:id',
    name: 'Report',
    component: () => import(/* webpackChunkName: "reportEntity" */ '@/views/ReportEntity.vue'),
  },
  {
    path: '/admin/reports/:id',
    name: 'AdminReport',
    component: () => import(/* webpackChunkName: "adminReportEntity" */ '@/views/AdminReportEntity.vue'),
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '@/views/Reports.vue'),
  },
  {
    path: '/transfers',
    name: 'Transfers',
    redirect: '/transfers/company',
    component: {
      // Inline declaration of a component that renders our <router-view>
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '/transfers/company',
        name: 'TransfersCompany',
        component: () => import(/* webpackChunkName: "transfers" */ '@/views/TransfersCompanies.vue'),
      },
      {
        path: '/transfers/driver',
        name: 'TransfersDrivers',
        component: () => import(/* webpackChunkName: "transfers" */ '@/views/TransfersDrivers.vue'),
      },
    ],
  },
  {
    path: '/transfers/:id',
    name: 'Transfer',
    component: () => import(/* webpackChunkName: "transfers" */ '@/views/TransferCompanyEntity.vue'),
  },
  {
    path: '/transfersDriver/:id',
    name: 'Transfer',
    component: () => import(/* webpackChunkName: "transfers" */ '@/views/TransferDriverEntity.vue'),
  },
  {
    path: '/tollroads',
    name: 'TollRoads',
    redirect: '/tollroads/drives',
    component: {
      // Inline declaration of a component that renders our <router-view>
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '/tollroads/drives',
        name: 'TollRoadsDrives',
        component: () => import(/* webpackChunkName: "transfers" */ '@/views/TollRoadsDrives.vue'),
      },
      {
        path: '/tollroads/import',
        name: 'TollRoadsImportation',
        component: () => import(/* webpackChunkName: "transfers" */ '@/views/TollRoadsImportation.vue'),
      },
      // {
      //   path: '/tollroads/log',
      //   name: 'TollRoadsImportation',
      //   component: () => import(/* webpackChunkName: "transfers" */ '@/views/TollRoadsImportation.vue'),
      // },
    ],
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    component: () => import(/* webpackChunkName: "vehicles" */ '@/views/Vehicles.vue'),
  },
  {
    path: '/vehicles/:id',
    name: 'Vehicle',
    component: () => import(/* webpackChunkName: "vehicles" */ '@/views/VehicleEntity.vue'),
  },
  {
    path: '/admin/vehicles/:id',
    name: 'AdminVehicle',
    component: () => import(/* webpackChunkName: "adminVehicles" */ '@/views/AdminVehicleEntity.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    redirect: '/profile/general',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    children: [
      {
        path: 'general',
        name: 'ProfileGeneral',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/ProfileGeneral.vue'),
      },
      {
        path: 'settings',
        name: 'ProfileSettings',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/ProfileSettings.vue'),
      },
      {
        path: 'documents',
        name: 'ProfileDocuments',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/ProfileDocuments.vue'),
      },
      {
        path: 'users',
        name: 'ProfileUsers',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/ProfileUsers.vue'),
      },
    ],
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/Clients.vue'),
  },
  {
    path: '/clients/:id',
    name: 'Client',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/ClientEntity.vue'),
  },
  {
    path: '/importDriversContract',
    name: 'DriversContract',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminImportDriversContract.vue'),
  },
  {
    path: '/drivers',
    name: 'Drivers',
    component: () => import(/* webpackChunkName: "drivers" */ '@/views/Drivers.vue'),
  },
  {
    path: '/drivers/:id',
    name: 'Driver',
    component: () => import(/* webpackChunkName: "drivers" */ '@/views/DriverEntity.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {
      layout: 'layout-full',
    },
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/ResetPassword.vue'),
    meta: {
      layout: 'layout-full',
      ignoreLogin: true,
    },
  },
  {
    path: '/driverAccident/:plateNumber',
    name: 'driverAccident',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "driverAccident" */ '@/components/AccidentEntityDetails.vue'),
    component: () => import(/* webpackChunkName: "driverAccident" */ '@/views/DriverAccident.vue'),
    meta: {
      layout: 'layout-full',
      ignoreLogin: true,
    },
  },
  {
    path: '/forgot',
    name: 'Forgot',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/Forgot.vue'),
    meta: {
      layout: 'layout-full',
      ignoreLogin: true,
    },
  },
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.ignoreLogin) {
    return next();
  }
  if (to.name === 'dashboard') {
    if (!router.app.$auth.isAuthenticated() && !store.state.auth.profileId && store.state.auth?.user?.claims?.isAdmin === "True") {
      router.push({ name: 'admin' });
    }
  }
  if (to.name === 'Login') { // check if "to"-route is "callback" and allow access
    if (!router.app.$auth.isAuthenticated()) {
      next();
    } else {
      next(false);
    }
  } else if (router.app.$auth.isAuthenticated()) { // if authenticated allow access
    next();
  } else { // trigger auth login
    router.app.$auth.login()
      .then(() => {
        next();
      })
      .catch(() => {
        router.push({ name: 'Login' });
      });
  }
  return false;
});

export default router;
