<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import LayoutInApp from '@/layouts/LayoutInApp.vue';
import LayoutFull from '@/layouts/LayoutFull.vue';

const shufersalCss = () => import('@/css/shufersal.css');
export default {
  name: 'App',
  components: {
    LayoutInApp,
    LayoutFull,
  },
  created() {
    this.cssPerSubdomain();
    this.isSosnaSubdomain();
  },
  computed: {
    layout() {
      if (this.$route.meta.layout) return this.$route.meta.layout;
      if (this.$auth.isAuthenticated()) return 'layout-in-app';
      // return this.$route.meta.layout || 'layout-in-app';
      return false;
    },
  },
  methods: {
    cssPerSubdomain() {
      switch (window.location.host) {
        // case 'localhost:8080':
        //   shufersalCss();
        //   break;
        case 'shufersal':
          shufersalCss();
          break;

        default:
          break;
      }
    },
    isSosnaSubdomain() {
      if (window.location.host.split('.')[0].includes('sosna')) this.$store.state.profile.sosna = true;
    }
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.button.is-primary {
  background-color: #ff7a59 !important;
  color: white !important;
  border-color: white !important;
}

.switch input[type=checkbox]:checked+.check {
  background: #ff7a59 !important;
}

.pagination-link.is-current {
  background-color: #00afd8 !important;
  border-color: #00afd8 !important;
}

.b-checkbox.checkbox input[type=checkbox]:checked+.check {
  background-color: #00afd8 !important;
  border-color: #00afd8 !important;
}

.b-tooltip.is-primary .tooltip-content {
  background: #ff7a59 !important;
}
.dialog .modal-card {
  max-width: 700px !important;
}
</style>
