/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { getField, updateField } from 'vuex-map-fields';
import ProfileService from '@/services/ProfileService';

const state = () => ({
    AllowContractHtmlAggregation: null,
    City: null,
    DriversLicenseUrl: null,
    HasLawyerTransferForm: null,
    HasPOA: null,
    HasTransferForm: null,
    HouseNumber: null,
    Id: null,
    IdNumber: null,
    Email: null,
    ImageUrl: null,
    IsLeasingCompany: null,
    IsManaged: null,
    Name: null,
    PendingVehicles: [],
    PowerOfAttornyUrl: null,
    PushId: null,
    Status: null,
    Street: null,
    ZipCode: null,
    IsForeignCitizen: null,
    Settings: { IsManaged: null, IsInAutomation: null, IsInMileon: null, LastCheckDate: null, AllowRePortImport: null },
    Users: [],
});

const getters = {
    getField,
    profileGeneral: (state) => ({
        City: state.City,
        HouseNumber: state.HouseNumber,
        IdNumber: state.IdNumber,
        Email: state.Email,
        Name: state.Name,
        Street: state.Street,
        ZipCode: state.ZipCode,
        IsForeignCitizen: state.IsForeignCitizen
    }),
    getDocuments: (state) => ({
        HasLawyerTransferForm: state.HasLawyerTransferForm,
        HasPOA: state.HasPOA,
        HasTransferForm: state.HasTransferForm,
    }),
    getProfileSettings: (state) => state.Settings,
    getProfileUsers: (state) => state.Users,
};

const mutations = {
    updateField,
    setProfile(state, data) {
        Object.assign(state, data);
    },
    setProfileSettings(state, data) {
        Object.assign(state.Settings, data);
    },
    setProfileUsers(state, data) {
        Object.assign(state.Users, data);
    },
    updateGeneral(state, data) {
        Object.assign(state, data);
    },
};

const actions = {
    get({ commit }) {
        return ProfileService.get()
        .then((r) => {
            commit('setProfile', r);
        });
    },
    getSettings({ commit }) {
        return ProfileService.getSettings()
        .then((r) => {
            commit('setProfileSettings', r.data);
        });
    },
    getUsers({ commit }, profileId) {
        return ProfileService.getUsers(profileId)
        .then((r) => {
            commit('setProfileUsers', r.data);
            return r.data;
        });
    },
    saveProfile({ state }) {
        return ProfileService.update(state);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
