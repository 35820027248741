/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import AuthService from '@/services/AuthService';
import axios from 'axios';

const state = () => ({
    token: null,
    accessToken: null,
    expiresAt: null,
    user: null,
    userId: null,
});

const getters = {

};

const mutations = {
    setToken(state, value) {
        state.token = value;
        if (value) {
            localStorage.setItem('refresh_token', value);
        } else {
            localStorage.removeItem('refresh_token');
        }
    },
    setAccessToken(state, value) {
        state.accessToken = value;
        // if (value) {
        //     localStorage.setItem('access_token', value);
        // } else {
        //     localStorage.removeItem('access_token');
        // }
    },
    setExpiresAt(state, value) {
        const expiresAt = (typeof value !== 'string') ? JSON.stringify(value) : value;
        state.expiresAt = expiresAt;
        if (value) {
            localStorage.setItem('expires_at', expiresAt);
        } else {
            localStorage.removeItem('expires_at');
        }
    },
    setUser(state, value) {
        state.user = value;
        if (value) {
            localStorage.setItem('user', JSON.stringify(value));
        } else {
            localStorage.removeItem('user');
        }
    },
    setProfileId(state, value) {
        if (value && value !== 1 && value !== 2) {
            state.profileId = value;
            axios.defaults.headers.common['Profile-Id'] = value;
            localStorage.setItem('profile_id', value);
        } else {
            state.profileId = null;
            axios.defaults.headers.common['Profile-Id'] = '';
            delete axios.defaults.headers.common["Profile-Id"];
            localStorage.removeItem('profile_id');
        }
    },
    setCustomer(state, value) {
        if (value) {
            state.user.claims.isAdmin = false;
            console.log(state.user);
        }
    }
};

const actions = {
    loadStorage({ commit }) {
        commit('setToken', localStorage.getItem('refresh_token'));
        // commit('setAccessToken', localStorage.getItem('access_token'));
        commit('setProfileId', localStorage.getItem('profile_id'));
        commit('setExpiresAt', localStorage.getItem('expires_at'));
        const user = localStorage.getItem('user');
        if (user) {
            commit('setUser', JSON.parse(localStorage.getItem('user')));
        }
        commit('setCustomer', localStorage.getItem('customer'));
    },
    refresh({ state, commit }) {
        const params = `grant_type=refresh_token&refresh_token=${state.token}`;
        return AuthService.authorize(params)
            .then(({ data }) => {
                if (data && data.access_token && data.refresh_token) {
                    commit('setExpiresAt', data.expires_in * 1000 + new Date().getTime());
                    commit('setAccessToken', data.access_token);
                    commit('setToken', data.refresh_token);
                    commit('setUser', {
                        email: data.userName,
                        claims: { isAdmin: data.isAdmin, isSignee: data.isSignee },
                    });
                    axios.defaults.headers.common.Authorization = `${data.token_type} ${data.access_token}`;
                    return data.access_token;
                }
                return null;
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
