import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const search = (model, cancelToken) => axios.post(`${BASE_URL}/api/Reports/Search`, model, {
    cancelToken,
});
const adminSearch = (model, cancelToken) => axios.post(`${BASE_URL}/api/Reports/Admin/Search`, model, {
    cancelToken,
});
const adminSearchTransfers = (model, cancelToken) => axios.post(`${BASE_URL}/api/Reports/Admin/Search/Transfers`, model, {
    cancelToken,
});
const getPayments = (model, cancelToken) => axios.get(`${BASE_URL}/api/Reports/Payment/Pay`, {
    cancelToken,
});
const exportXsl = (model) => axios.post(`${BASE_URL}/api/Reports/Search/xlsx`, model, {
    responseType: 'blob',
});
const exportXslAdmin = (model) => axios.post(`${BASE_URL}/api/Reports/Admin/Search/Xlsx`, model, {
    responseType: 'blob',
});
const exportPaidReportsXsl = (model) => axios.post(`${BASE_URL}/api/Reports/Search/xlsx?exportType=1`, model, {
    responseType: 'blob',
});
const exportReportsXslBy = (model, type) => axios.post(`${BASE_URL}/api/Reports/Search/xlsx?exportType=${model.IsPaid ? 1 : 0}&groupingType=${type}`, model, {
    responseType: 'blob',
});
const removeFromProfile = (data) => axios.put(`${BASE_URL}/api/Reports/RemoveFromProfile`, data);

const getReportQrDetails = (url) => axios.get(`${BASE_URL}/api/Reports/ParseUrl?url=${url}`);
const getReport = (reportId) => axios.get(`${BASE_URL}/api/Reports/${reportId}`);
const getReportAdmin = (reportId) => axios.get(`${BASE_URL}/api/Reports/Admin/${reportId}`);
const getReportContract = (reportId) => axios.get(`${BASE_URL}/api/Reports/${reportId}/Contracts`);
const getDriverContract = (id) => axios.get(`${BASE_URL}/api/Reports/${id}/DriverContract`);
const getVehicleContract = (id) => axios.get(`${BASE_URL}/api/Reports/${id}/VehicleContract`);
const getReportImages = (reportId) => axios.get(`${BASE_URL}/api/Reports/${reportId}/Images?isApp=false`);
const getReportImagesAdmin = (reportId) => axios.get(`${BASE_URL}/api/Reports/Admin/${reportId}/Images?isApp=false`);
const update = (reportId, data) => axios.put(`${BASE_URL}/api/Reports/${reportId}`, data);
const updateAdmin = (reportId, data) => axios.put(`${BASE_URL}/api/Reports/Admin/${reportId}`, data);
const setPayment = (reportId, data) => axios.put(`${BASE_URL}/api/Reports/${reportId}/Payment`, data);
const setPayments = (data) => axios.put(`${BASE_URL}/api/Reports/Payment`, data);
const approvePayment = (data) => axios.put(`${BASE_URL}/api/Reports/ApprovePayment`, data);
const disapprovePayment = (data) => axios.put(`${BASE_URL}/api/Reports/DisapprovePayment`, data);
const setAsPaid = (data) => axios.put(`${BASE_URL}/api/Reports/Payment`, data);
const setStatus = (reportId, data) => axios.put(`${BASE_URL}/api/Reports/${reportId}/StatusEvents`, data);
const setStatuses = (data) => axios.put(`${BASE_URL}/api/Reports/Status`, data);
const setDeliveryMethod = (reportId, data) => axios.put(`${BASE_URL}/api/Reports/${reportId}/DeliveryEvent`, data);
const setAsUnpaid = (id) => axios.put(`${BASE_URL}/api/Reports/Admin/${id}/Payment/Unpaid`);
const importReports = (model) => axios.post(`${BASE_URL}/api/Reports/Batch`, model);
const importSingleReport = (model) => axios.post(`${BASE_URL}/api/Reports/Import/Single`, model);
const importSmartReports = (model) => axios.post(`${BASE_URL}/api/Reports/Batch/Smart`, model);
const importPolice = (model) => axios.post(`${BASE_URL}/api/Reports/FromPolice`, model);
const scanData = (fields, vehicles) => axios.put(`${BASE_URL}/api/Reports/Coordinates?emptyFieldsOnly=${fields}&managedVehiclesOnly=${vehicles}`);
const scanReports = (fields) => axios.put(`${BASE_URL}/api/Reports/FromMunicipality`, fields);
const updateReportAlert = (profileId, reportNumber, paidField) => axios.put(`${BASE_URL}/api/Reports/Alerts?profileId=${profileId}&reportNumber=${reportNumber}&unpaidOnly=${paidField}`);
const importReportsStatus = (uuid) => axios.get(`${BASE_URL}/api/Reports/Batch/${uuid}`);
const deleteStatus = (id, type) => axios.delete(`${BASE_URL}/api/Reports/${id}/Alert/${type}`);
const createReport = (data, skipVerification) => axios.post(`${BASE_URL}/api/Reports?skipVerification=${skipVerification}`, data);
const fetchReportData = (municipalityId, plateNumber, reportNumber) => axios.get(`${BASE_URL}/api/Reports/FromMunicipality?municipalityId=${municipalityId}&plateNumber=${plateNumber}&reportNumber=${reportNumber}`);
const scanDetailsFromMunic = (data) => axios.put(`${BASE_URL}/api/Reports/FromMunicipality`, data);
const generateAlerts = (data) => axios.put(`${BASE_URL}/api/Reports/Alerts?reportNumber=${data.reportNumber}&unpaidOnly=true`);
const loadReportByNumber = (reportNumber, municipalityId) => axios.get(`${BASE_URL}/api/Reports/ByReportNumber/${reportNumber}/${municipalityId}`);
const getFields = (isSmartImport = false) => axios.get(`${BASE_URL}/api/Reports/Fields?isSmartImport=${isSmartImport}`);
const sendAllTransfers = (type) => axios.post(type ? `${BASE_URL}/api/Reports/SendAllTransfers/${type}` : `${BASE_URL}/api/Reports/SendAllTransfers`);
const cancelAllTransfers = () => axios.put(`${BASE_URL}/api/Reports/CancelSendingAllTransfers`);
const forMap = async (model, cancelToken) => {
    if (forMap.cache) return forMap.cache;
    const { data } = await axios.post(`${BASE_URL}/api/Reports/ForMap`, model, {
        cancelToken,
    });
    forMap.cache = data;

    return forMap.cache;
};
const getYearlyStatistics = (year) => axios.get(`${BASE_URL}/api/Statistics/Yearly/${year}`);

const produceJerusalemSingleReference = (data) => axios.put(`${BASE_URL}/api/Reports/Jerusalem/MergeReferences`, data);

export default {
    search,
    getReportContract,
    getPayments,
    getReport,
    getDriverContract,
    getVehicleContract,
    update,
    setPayment,
    getReportImages,
    getReportImagesAdmin,
    approvePayment,
    disapprovePayment,
    setAsPaid,
    exportXsl,
    exportPaidReportsXsl,
    setStatus,
    setStatuses,
    setAsUnpaid,
    setPayments,
    deleteStatus,
    setDeliveryMethod,
    createReport,
    fetchReportData,
    loadReportByNumber,
    forMap,
    getYearlyStatistics,
    produceJerusalemSingleReference,
    adminSearch,
    adminSearchTransfers,
    getReportAdmin,
    updateAdmin,
    getFields,
    importReports,
    importSingleReport,
    importReportsStatus,
    scanDetailsFromMunic,
    generateAlerts,
    importPolice,
    scanData,
    scanReports,
    updateReportAlert,
    importSmartReports,
    sendAllTransfers,
    cancelAllTransfers,
    getReportQrDetails,
    exportReportsXslBy,
    exportXslAdmin,
    removeFromProfile
};
