/* eslint camelcase: "off", func-names: "off" */

import authService from "@/services/AuthService";
import axios from "axios";
import Vue from "vue";
import store from "./store";

const auth = new Vue({
  computed: {
    expiresAt() {
      return store.state.auth.expiresAt;
    },
    token() {
      return store.state.auth.token;
    },
    accessToken() {
      return store.state.auth.accessToken;
    },
    user() {
      const { user } = store.state.auth;
      if (user) {
        return JSON.parse(user);
      }
      return null;
    },
    profileId() {
      return store.state.auth.profileId;
    }
  },
  created() {
    store.dispatch("auth/loadStorage");
    if (this.isAuthenticated()) {
      axios.defaults.headers.common.Authorization = `Bearer ${this.accessToken}`;
      if (this.profileId) {
        axios.defaults.headers.common["Profile-Id"] = this.profileId;
      }
    }
  },
  methods: {
    login(model) {
      if (!model) {
        if (!this.token) return Promise.reject(new Error("no refresh token!"));
        const params = `grant_type=refresh_token&refresh_token=${this.token}`;
        return authService.authorize(params).then((r) => {
          this.handleAuthentication(r.data);
        });
      }
      // eslint-disable-next-line no-unused-expressions
      localStorage.removeItem('profile_id');
      const params = `grant_type=password&username=${model.email}&password=${model.password}`;
      return authService.authorize(params).then((r) => this.handleAuthentication(r.data));
    },
    logout() {
      return new Promise((resolve) => {
        store.commit("auth/setUser", null);
        store.commit("auth/setToken", null);
        store.commit("auth/setAccessToken", null);
        store.commit("auth/setExpiresAt", null);
        store.commit("auth/setUser", null);
        store.commit("auth/setProfileId", null);
        store.commit("dashboard/clearCache");
        authService.logout();
        resolve();
      });
    },
    isAuthenticated() {
      if (this.accessToken && this.expiresAt) {
        return new Date().getTime() < new Date(parseFloat(this.expiresAt));
      }
      return false;
    },
    handleAuthentication(authResult) {
      return new Promise((resolve) => {
        if (authResult && authResult.access_token && authResult.refresh_token) {
          store.commit("auth/setExpiresAt", authResult.expires_in * 1000 + new Date().getTime());
          store.commit("auth/setAccessToken", authResult.access_token);
          store.commit("auth/setToken", authResult.refresh_token);
          store.commit("auth/setUser", {
            email: authResult.userName,
            claims: { isAdmin: authResult.isAdmin, isSignee: authResult.isSignee }
          });
          axios.defaults.headers.common.Authorization = `${authResult.token_type} ${authResult.access_token}`;
          resolve();
        }
      });
    }
  }
});

export default {
  install: () => {
    Vue.prototype.$auth = auth;
  }
};
