var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "LayoutInApp" }, [
    _c(
      "div",
      { attrs: { id: "nav" } },
      [
        _vm._m(0),
        _vm.isAdminWithoutProfileId
          ? _c(
              "div",
              { staticClass: "links" },
              [
                _c("router-link", { attrs: { to: "/admin" } }, [
                  _vm._v("אדמיניסטרציה")
                ]),
                _c("router-link", { attrs: { to: "/admin/tasks" } }, [
                  _vm._v("משימות")
                ])
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "links" },
              [
                _c("router-link", { attrs: { to: "/dashboard" } }, [
                  _vm._v("ראשי")
                ]),
                _vm.isLeasingCompany && !_vm.isSosnaPortal
                  ? _c("router-link", { attrs: { to: "/clients" } }, [
                      _vm._v("לקוחות")
                    ])
                  : _vm._e(),
                _c("router-link", { attrs: { to: "/vehicles" } }, [
                  _vm._v("רכבים")
                ]),
                _c("router-link", { attrs: { to: "/reports" } }, [
                  _vm._v("דוחות")
                ]),
                !_vm.isSosnaPortal
                  ? _c(
                      "b-dropdown",
                      {
                        attrs: {
                          position: "is-bottom-left",
                          "aria-role": "list",
                          animation: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "trigger",
                              fn: function() {
                                return [
                                  _c("div", [
                                    _c(
                                      "a",
                                      {
                                        class: {
                                          "router-link-active": _vm.isRouteActive(
                                            "/transfers"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(" הסבות "),
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "chevron-down",
                                            size: "is-small"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          772232649
                        )
                      },
                      [
                        _c(
                          "b-dropdown-item",
                          { attrs: { "aria-role": "listitem" } },
                          [
                            _vm.isLeasingCompany
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      exact: "",
                                      to: "/transfers/company"
                                    }
                                  },
                                  [_vm._v("הסבות לחברות")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          { attrs: { "aria-role": "listitem" } },
                          [
                            _c(
                              "router-link",
                              { attrs: { exact: "", to: "/transfers/driver" } },
                              [_vm._v("הסבות לנהגים")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isSosnaPortal
                  ? _c(
                      "b-dropdown",
                      {
                        attrs: {
                          position: "is-bottom-left",
                          "aria-role": "list",
                          animation: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "trigger",
                              fn: function() {
                                return [
                                  _c("div", [
                                    _c(
                                      "a",
                                      {
                                        class: {
                                          "router-link-active": _vm.isRouteActive(
                                            "/drivers"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(" נהגים "),
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "chevron-down",
                                            size: "is-small"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3900233829
                        )
                      },
                      [
                        _c(
                          "b-dropdown-item",
                          { attrs: { "aria-role": "listitem" } },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "active",
                                attrs: { exact: "", to: "/drivers" }
                              },
                              [_vm._v("רשימת נהגים")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "active",
                                attrs: {
                                  exact: "",
                                  to: "/importDriversContract"
                                }
                              },
                              [_vm._v("ייבוא נהגים")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isLeasingCompany && !_vm.isSosnaPortal
                  ? _c(
                      "router-link",
                      { staticClass: "active", attrs: { to: "/accidents" } },
                      [_vm._v("תאונות")]
                    )
                  : _vm._e(),
                !_vm.isSosnaPortal
                  ? _c(
                      "b-dropdown",
                      {
                        attrs: {
                          position: "is-bottom-left",
                          "aria-role": "list",
                          animation: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "trigger",
                              fn: function() {
                                return [
                                  _c("div", [
                                    _c(
                                      "a",
                                      {
                                        class: {
                                          "router-link-active": _vm.isRouteActive(
                                            "/tollroads"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(" כבישי אגרה "),
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "chevron-down",
                                            size: "is-small"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1095111943
                        )
                      },
                      [
                        _c(
                          "b-dropdown-item",
                          { attrs: { "aria-role": "listitem" } },
                          [
                            _c(
                              "router-link",
                              { attrs: { exact: "", to: "/tollroads/drives" } },
                              [_vm._v("נסיעות")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          { attrs: { "aria-role": "listitem" } },
                          [
                            _c(
                              "router-link",
                              { attrs: { exact: "", to: "/tollroads/import" } },
                              [_vm._v("ייבוא נסיעות מקובץ Excel")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isSosnaPortal
                  ? _c(
                      "router-link",
                      { staticClass: "active", attrs: { to: "/tasks" } },
                      [_vm._v("משימות")]
                    )
                  : _vm._e()
              ],
              1
            ),
        _c(
          "b-dropdown",
          {
            attrs: { "aria-role": "list", animation: "" },
            scopedSlots: _vm._u([
              {
                key: "trigger",
                fn: function() {
                  return [
                    _c("div", { staticClass: "profile" }, [
                      _vm.user
                        ? _c("div", { staticClass: "avatar" }, [
                            _vm.profileIcon
                              ? _c("div", { staticClass: "icon" }, [
                                  _c("img", {
                                    staticClass: "img-responsive",
                                    attrs: { src: _vm.profileIcon, alt: "" }
                                  })
                                ])
                              : _vm._e(),
                            _c(
                              "span",
                              [
                                _c("b-icon", {
                                  attrs: { icon: "chevron-down" }
                                }),
                                _vm._v(" " + _vm._s(_vm.user) + " ")
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      !_vm.user
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "is-flex is-align-items-center avatar-placeholder"
                              },
                              [
                                _vm._v(" Admin "),
                                _c("b-icon", {
                                  attrs: { icon: "chevron-down" }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ])
                  ]
                },
                proxy: true
              }
            ])
          },
          [
            _c(
              "b-dropdown-item",
              {
                attrs: { "aria-role": "listitem" },
                on: { click: _vm.goToProfile }
              },
              [_vm._v("הגדרות")]
            ),
            _vm.otherProfiles.length > 0
              ? _c(
                  "section",
                  { staticClass: "other-profiles" },
                  _vm._l(_vm.otherProfiles, function(profile) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: profile.Id,
                        attrs: { "aria-role": "listitem" },
                        on: {
                          click: function($event) {
                            return _vm.switchToOtherUser(profile)
                          }
                        }
                      },
                      [_vm._v(" עבור ל" + _vm._s(profile.Name) + " ")]
                    )
                  }),
                  1
                )
              : _vm._e(),
            _vm.isUserProfile && (_vm.isAdmin || _vm.isCustomer)
              ? _c(
                  "b-dropdown-item",
                  {
                    attrs: { "aria-role": "listitem" },
                    on: { click: _vm.signoutProfile }
                  },
                  [_vm._v("צא מלקוח")]
                )
              : _vm._e(),
            _c(
              "b-dropdown-item",
              {
                attrs: { "aria-role": "listitem" },
                on: { click: _vm.signout }
              },
              [_vm._v("יציאה")]
            )
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "LayoutInApp__Component" }, [_c("router-view")], 1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        staticClass: "img-responsive",
        attrs: { src: require("@/assets/logo.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }