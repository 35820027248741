import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const get = (headers) => (axios.get(`${BASE_URL}/api/Profiles`, headers)
    .then((r) => r.data)
    .catch((err) => console.log(err))
);

const getSettings = () => axios.get(`${BASE_URL}/api/Profiles/Settings`);
const getProfileAutomaticTransfer = () => axios.get(`${BASE_URL}/api/Profiles/AutomaticTransfer`);
const addProfileAutomaticTransfer = () => axios.post(`${BASE_URL}/api/Profiles/AutomaticTransfer`);
const deleteProfileAutomaticTransfer = () => axios.delete(`${BASE_URL}/api/Profiles/AutomaticTransfer`);
const addProfile = (data) => axios.post(`${BASE_URL}/api/Profiles`, data);
const update = (data, headers) => axios.put(`${BASE_URL}/api/Profiles`, data, headers);
const users = () => axios.get(`${BASE_URL}/api/Profiles/My/Users`);
const usersTask = () => axios.get(`${BASE_URL}/api/Profiles/My/UsersTask`);
const adminUsersTask = () => axios.get(`${BASE_URL}/api/Profiles/Admin/UsersTask`);
const all = (managedOnly) => axios.get(`${BASE_URL}/api/Profiles/All?managedOnly=${managedOnly}`);
const allMapped = (managedOnly) => (axios.get(`${BASE_URL}/api/Profiles/All?managedOnly=${managedOnly}`)
    .then((r) => {
        const cache = r.data.map((i) => ({
            Value: i.Id,
            Text: i.Name,
            IdNumber: i.IdNumber,
            IsLeasingCompany: i.IsLeasingCompany,
            ForbidTransfer: i.ForbidTransfer
        }));
        return cache;
    })
    .catch((err) => console.log(err)));
const getCrawlerTypes = () => (axios.get(`${BASE_URL}/api/CrawlerTypes`)
    .then((r) => {
        const cache = r.data.map((i) => ({
            Value: i.Id,
            Text: i.Name,
            IdNumber: i.IdNumber,
        }));
        cache.unshift({ Value: "null", Text: "הכל" });
        return cache;
    })
    .catch((err) => console.log(err)));
const updateDocument = (profileHtmlField, html) => axios.put(`${BASE_URL}/api/Profiles/TransferHtml/${profileHtmlField}`, { Html: html });
const deleteDocument = (profileHtmlField) => axios.delete(`${BASE_URL}/api/Profiles/TransferHtml/${profileHtmlField}`);
const getDocument = (profileHtmlField) => axios.get(`${BASE_URL}/api/Profiles/TransferHtml/${profileHtmlField}`);
const setIsManaged = (isManaged) => axios.put(`${BASE_URL}/api/Profiles/Managed/${isManaged}`);
const addAutomation = () => axios.put(`${BASE_URL}/api/Profiles/Automation/Add`);
const addMileon = () => axios.put(`${BASE_URL}/api/Profiles/Mileon/Add`);
const removeMileon = () => axios.put(`${BASE_URL}/api/Profiles/Mileon/Remove`);
const allowReport = (bool) => axios.put(`${BASE_URL}/api/Profiles/AllowRePortImport/${bool}`);
const removeAutomation = () => axios.put(`${BASE_URL}/api/Profiles/Automation/Remove`);
const setFineCenterPassword = (password) => axios.put(`${BASE_URL}/api/Profiles/FineCenter/ChangePassword/Model`, { Password: password });
const updateProfileTracking = (id, data) => axios.put(`${BASE_URL}/api/Profiles/TrackingProviders/${id}`, data);
const getUsers = (profileId) => axios.get(`${BASE_URL}/api/Profiles/${profileId}/Users`);
const getClientAdmin = (clientId) => axios.get(`${BASE_URL}/api/Profiles/${clientId}`);
const updateClaims = (profileId, userId, claims) => axios.put(`${BASE_URL}/api/Profiles/${profileId}/Users/${userId}/Claims`, { Claims: claims });
const updateLastCheckDate = () => axios.put(`${BASE_URL}/api/Profiles/LastCheckDate`);
const getProfileKeys = () => axios.get(`${BASE_URL}/api/Profiles/AppKeys`);
const deleteUser = (profileId, userId) => axios.delete(`${BASE_URL}/api/Profiles/${profileId}/Users/${userId}`);

export default {
    get,
    update,
    users,
    all,
    updateDocument,
    getDocument,
    deleteDocument,
    getSettings,
    getProfileAutomaticTransfer,
    addProfileAutomaticTransfer,
    deleteProfileAutomaticTransfer,
    setIsManaged,
    addAutomation,
    addMileon,
    removeMileon,
    allowReport,
    removeAutomation,
    setFineCenterPassword,
    updateProfileTracking,
    getUsers,
    updateClaims,
    updateLastCheckDate,
    getProfileKeys,
    deleteUser,
    usersTask,
    adminUsersTask,
    allMapped,
    getCrawlerTypes,
    addProfile,
    getClientAdmin
};
