import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const search = (data) => axios.post(`${BASE_URL}/api/Drivers/search`, data);
const exportXsl = (activeOnly, managersOnly) => axios.get(`${BASE_URL}/api/Drivers/Xlsx?activeOnly=${activeOnly}&managersOnly=${managersOnly}`, {
    responseType: 'blob',
});

const get = (force) => {
    if (get.cache && !force) return Promise.resolve(get.cache);
    return axios.get(`${BASE_URL}/api/Drivers`)
        .then((r) => {
            const cache = r.data.map((i) => ({ Value: i.Id, Text: i.Name }));
            cache.unshift({ Value: 0, Text: 'ללא נהג' });
            get.cache = cache;
            return cache;
        })
        .catch((err) => console.log(err));
};

const getDriver = (id) => axios.get(`${BASE_URL}/api/Drivers/${id}`);
const getDriverAdmin = (id) => axios.get(`${BASE_URL}/api/Drivers/Admin/${id}`);
const deleteDriver = (id) => axios.delete(`${BASE_URL}/api/Drivers/${id}`);
const updateDriver = (id, model, headers) => axios.put(`${BASE_URL}/api/Drivers/${id}`, model, headers);
const setReportDriver = (data) => axios.put(`${BASE_URL}/api/Drivers/Reports`, data);
const setTransferStatus = (data) => axios.put(`${BASE_URL}/api/Drivers/TransferStatus`, data);
const putDocument = (driverId, fieldType, html) => axios.put(`${BASE_URL}/api/Drivers/${driverId}/TransferFields/${fieldType}`, { Html: html });
const deleteDocument = (driverId, fieldType) => axios.delete(`${BASE_URL}/api/Drivers/${driverId}/TransferFields/${fieldType}`);
const getDocument = (driverId, fieldType) => axios.get(`${BASE_URL}/api/Drivers/${driverId}/TransferFields/${fieldType}`);
const postDriver = (model, config) => axios.post(`${BASE_URL}/api/Drivers`, model, config);
const getContract = (id) => axios.get(`${BASE_URL}/api/Drivers/Contracts/${id}`);
const addContract = (driverId, data) => axios.post(`${BASE_URL}/api/Drivers/${driverId}/Vehicles`, data);
const transferReportDriver = (data) => axios.post(`${BASE_URL}/api/Drivers/TransferReports/ByIds`, data);
const updateContract = (driverId, data) => axios.put(`${BASE_URL}/api/Drivers/${driverId}/Vehicles`, data);
const deleteContract = (driverId, contractId) => axios.delete(`${BASE_URL}/api/Drivers/${driverId}/Vehicles/${contractId}`);
const printTransfers = (data) => axios.post(`${BASE_URL}/api/Drivers/TransferReports/Html/ByIds`, data);
// const getDriverContractHistory = (id) => axios.get(`${BASE_URL}/api/Archive/VehicleContracts/${id}`);

const deleteDriverDocument = (contractId, type) => axios.delete(`${BASE_URL}/api/Drivers/Contracts/${contractId}/${type}`);
const putDriverDocument = (contractId, type, data) => axios.put(`${BASE_URL}/api/Drivers/Contracts/${contractId}/${type}`, data);
const getDriverDocument = (contractId, type) => axios.get(`${BASE_URL}/api/Drivers/Contracts/${contractId}/${type}`);

const postDriversContract = (data) => axios.post(`${BASE_URL}/api/Drivers/Import`, data);

const searchCity = (pageId, pageSize, term) => axios.get(`${BASE_URL}/api/GovMunicipalities?pageId=${pageId}&pageSize=${pageSize}&term=${term}`);
const searchCityAddress = (cityName, pageId, pageSize, term) => axios.get(`${BASE_URL}/api/GovMunicipalities/ByName/Streets?cityName=${cityName}&pageId=${pageId}&pageSize=${pageSize}&term=${term}`);

export default {
    get,
    search,
    getDriver,
    getDriverAdmin,
    deleteDriver,
    updateDriver,
    setReportDriver,
    setTransferStatus,
    exportXsl,
    putDocument,
    deleteDocument,
    getDocument,
    postDriver,
    getContract,
    addContract,
    updateContract,
    deleteContract,
    transferReportDriver,
    printTransfers,
    deleteDriverDocument,
    putDriverDocument,
    getDriverDocument,
    postDriversContract,
    searchCity,
    searchCityAddress
};
