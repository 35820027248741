<template>
  <div class="LayoutInApp">
    <div id="nav">
      <div class="logo">
        <img src="@/assets/logo.png" class="img-responsive" alt="" />
      </div>
      <div class="links" v-if="isAdminWithoutProfileId">
        <router-link to="/admin">אדמיניסטרציה</router-link>
        <router-link to="/admin/tasks">משימות</router-link>
      </div>
      <div class="links" v-else>
        <router-link to="/dashboard">ראשי</router-link>
        <router-link v-if="isLeasingCompany && !isSosnaPortal" to="/clients">לקוחות</router-link>
        <router-link to="/vehicles">רכבים</router-link>
        <router-link to="/reports">דוחות</router-link>
        <b-dropdown v-if="!isSosnaPortal" position="is-bottom-left" aria-role="list" animation="">
          <template #trigger>
            <div>
              <a @click.prevent :class="{ 'router-link-active': isRouteActive('/transfers') }">
                הסבות
                <b-icon icon="chevron-down" size="is-small" />
              </a>
            </div>
          </template>

          <b-dropdown-item aria-role="listitem">
            <router-link v-if="isLeasingCompany" exact to="/transfers/company">הסבות לחברות</router-link>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem">
            <router-link exact to="/transfers/driver">הסבות לנהגים</router-link>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown v-if="!isSosnaPortal" position="is-bottom-left" aria-role="list" animation="">
          <template #trigger>
            <div>
              <a @click.prevent :class="{ 'router-link-active': isRouteActive('/drivers') }">
                נהגים
                <b-icon icon="chevron-down" size="is-small" />
              </a>
            </div>
          </template>

          <b-dropdown-item aria-role="listitem">
            <router-link exact class="active" to="/drivers">רשימת נהגים</router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link exact class="active" to="/importDriversContract">ייבוא נהגים</router-link>
          </b-dropdown-item>
        </b-dropdown>
        <router-link v-if="!isLeasingCompany && !isSosnaPortal" class="active" to="/accidents">תאונות</router-link>
        <b-dropdown v-if="!isSosnaPortal" position="is-bottom-left" aria-role="list" animation="">
          <template #trigger>
            <div>
              <a @click.prevent :class="{ 'router-link-active': isRouteActive('/tollroads') }">
                כבישי אגרה
                <b-icon icon="chevron-down" size="is-small" />
              </a>
            </div>
          </template>

          <b-dropdown-item aria-role="listitem">
            <router-link exact to="/tollroads/drives">נסיעות</router-link>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem">
            <router-link exact to="/tollroads/import">ייבוא נסיעות מקובץ Excel</router-link>
          </b-dropdown-item>
          <!-- <b-dropdown-item aria-role="listitem">
            <router-link exact to="/tollroads/log">היסטורית ייבוא</router-link>
          </b-dropdown-item> -->
        </b-dropdown>
        <router-link v-if="!isSosnaPortal" class="active" to="/tasks">משימות</router-link>
      </div>
      <b-dropdown aria-role="list" animation="">
        <template #trigger>
          <div class="profile">
            <div class="avatar" v-if="user">
              <div class="icon" v-if="profileIcon">
                <img :src="profileIcon" class="img-responsive" alt="" />
              </div>
              <span>
                <b-icon icon="chevron-down" />
                {{ user }}
              </span>
            </div>
            <div v-if="!user">
              <span class="is-flex is-align-items-center avatar-placeholder">
                Admin
                <b-icon icon="chevron-down" />
              </span>
            </div>
          </div>
        </template>

        <b-dropdown-item @click="goToProfile" aria-role="listitem">הגדרות</b-dropdown-item>
        <section class="other-profiles" v-if="otherProfiles.length > 0">
          <b-dropdown-item v-for="profile in otherProfiles" :key="profile.Id" aria-role="listitem"
            @click="switchToOtherUser(profile)">
            עבור ל{{ profile.Name }}
          </b-dropdown-item>
        </section>
        <b-dropdown-item v-if="isUserProfile && (isAdmin || isCustomer)" @click="signoutProfile" aria-role="listitem">צא
          מלקוח</b-dropdown-item>
        <b-dropdown-item @click="signout" aria-role="listitem">יציאה</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="LayoutInApp__Component">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import ProfileService from "../services/ProfileService";
import AuthService from "../services/AuthService";

export default {
  name: "LayoutInApp",
  data() {
    return {
      otherProfiles: [],
    };
  },
  created() {
    this.$store.dispatch("auth/loadStorage");
    this.loadProfile().then(() => {
      if (!this.isAdmin) this.otherUserProfiles();
      this.setProfileId(this.$store.state.profile.Id);
    });
  },
  computed: {
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    isCustomer() {
      return localStorage.getItem('customer');
    },
    user() {
      return this.$store.state.profile?.Name;
    },
    isAuthenticated() {
      return this.$auth.isAuthenticated();
    },
    isUserProfile() {
      return this.$store.state.auth.profileId;
    },
    isSosnaPortal() {
      return this.$store.state.profile.sosna;
    },
    profileIcon() {
      return this.$store.state.profile?.ImageUrl;
    },
    isAdminWithoutProfileId() {
      return !this.$store.state.auth.profileId && this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    isAdminWithProfileId() {
      return this.$store.state.auth.profileId && this.$store.state.auth?.user?.claims?.isAdmin === "True";
    }
  },
  methods: {
    ...mapMutations("auth", ["setProfileId", "setProfile"]),

    userId() {
      return this.$store.state.profile?.Id;
    },
    signoutProfile() {
      this.setProfileId();
      // this.$store.commit("dashboard/clearCache");
      window.location.href = this.$router.resolve({ name: "admin" }).href;
      if (this.isCustomer) {
        localStorage.removeItem('customer');
      }
    },
    isRouteActive(route) {
      return this.$route.matched.some((r) => r.path === route);
    },
    goToProfile() {
      this.$router.push({ path: "/profile" });
    },
    signout() {
      this.$auth.logout().then(() => {
        this.$router.replace({ path: "/login" });
        window.location.href = this.$router.resolve({ name: "login" }).href;
      });
    },
    async otherUserProfiles() {
      const { data: profiles } = await ProfileService.all(false);
      this.otherProfiles = profiles.filter((prof) => prof.Id !== this.userId());
    },
    async switchToOtherUser(profile) {
      if (this.isAuthenticated) {
        this.setProfileId(profile.Id);
        await AuthService.ping();
        // this.$store.commit("dashboard/clearCache");
        window.location.href = this.$router.resolve({ name: "dashboard" }).href;
        if (!this.isAdmin) this.otherUserProfiles();
      }
    },
    async loadProfile() {
      await this.$store.dispatch("profile/get");
      if (this.isAdmin) {
        await this.$store.dispatch("profile/getSettings");
      }
    },
  },
  // watch: {
  //   isAuthenticated(newValue) {
  //     if (newValue) {
  //       this.loadProfile().then(() => {
  //         if (!this.isAdmin) this.otherUserProfiles();
  //       });
  //     }
  //   },
  // },
};
</script>

<style scoped lang="scss">
.other-profiles {
  max-height: 300px;
  overflow: auto;
}
#nav {
  padding: 0;
  margin: 0;
  height: 50px;
  background-color: #2e3f50;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 5;
  top: 0;

  ::v-deep {
    .dropdown:active {
      transform: scale(0.98);
    }
  }

  .logo {
    height: 35px;
    width: 200px;
  }

  .dropdown:hover {
    background-color: #253342;
  }

  .links {
    display: flex;
    width: 100%;
    min-width: 730px;
    justify-content: start;
    height: 100%;
    align-items: center;

    a {
      font-size: 14px;
      display: flex;
      align-items: center;
      height: 100%;

      &:active {
        transform: scale(0.98);
      }

      &:hover {
        background-color: #253342;
      }
    }

    ::v-deep .dropdown {
      padding: 0;

      .dropdown-content {
        width: 100%;
      }

      .dropdown-trigger {
        height: 100%;
        margin: 0;
        display: flex;

        .icon {
          margin-inline-start: 4px;
        }
      }

      .dropdown-item {
        background-color: #2e3f50;
        height: unset;
        padding: 0;
        margin: 0;
        border-radius: 0;

        a {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .dropdown {
    padding: 0 20px;
    display: flex;
    align-items: center;
    color: white;
    height: 100%;
    cursor: pointer;
    position: relative;
    z-index: 2;

    .dropdown-item {
      color: black;
    }
  }

  .profile {
    display: flex;
    align-items: center;
    min-width: 120px;

    .avatar {
      display: flex;
      color: white;
      min-width: 230px;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: start;
      font-size: 14px;

      &>span {
        display: flex;
        align-items: center;
      }

      .icon {
        width: 30px;
        height: 25px;
        border-radius: 50%;
        margin-inline-start: 10px;

        span {
          text-align: center;
          display: block;
          width: 100%;
        }
      }
    }
  }

  a {
    color: white;
    text-decoration: none;
    padding: 7px 25px;
    border-radius: 3px;

    &.active {
      // color: white;
      // background-color: #41b883;
      cursor: pointer;
    }

    &.router-link-active {
      // color: #42b983;
      color: #ef925d;
      background-color: #253342;
    }
  }
}

.LayoutInApp__Component {
  height: calc(100vh - 50px);

  &>div {
    height: 100%;
  }
}

.avatar-placeholder {
  .b-skeleton:last-of-type {
    margin: 0;
    margin-right: 10px;
  }
}

@media screen and (max-width: 900px) {
  #nav {
    overflow-x: auto;
    max-width: 900px;
    overflow-y: hidden;

    .profile {
      padding: 0;

      .avatar {
        width: 120px;
        justify-content: center;
      }
    }
  }
}
</style>
