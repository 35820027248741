/* eslint no-shadow: ["error", { "allow": ["tabs"] }] */

import Vue from "vue";
import Vuex from "vuex";
import tabs from "./modules/tabsStore";
import tabModule from "./modules/tabModule";
import report from "./modules/reportStore";
import transfer from "./modules/transferStore";
import transferDriver from "./modules/transferDriverStore";
import vehicle from "./modules/vehicleStore";
import auth from "./modules/authStore";
import profile from "./modules/profileStore";
import client from "./modules/clientStore";
import driver from "./modules/driverStore";
import contract from "./modules/contractStore";
import driverContract from "./modules/driverContractStore";
import accident from "./modules/accidentStore";
import dashboard from "./modules/dashboardStore";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  strict: debug,
  modules: {
    auth,
    profile,
    tabs,
    report,
    transfer,
    transferDriver,
    vehicle,
    client,
    driver,
    contract,
    accident,
    driverContract,
    dashboard
  }
});

const registerModule = ({ module }) => {
  // register a module `myModule`
  store.registerModule(["tabs", module], tabModule);
};

export default store;

export { registerModule };
